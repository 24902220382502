// TEXT
.e-text-hypend-auto {
  -moz-hyphens:auto;
  -ms-hyphens:auto;
  -webkit-hyphens:auto;
  -o-hyphens:auto;
  hyphens:auto;
  word-wrap:break-word;
}


.e-text-line-height-em {
  &-1-2 {
    line-height:1.2em !important;
  }
}

.e-text-decoration {
  &-none {
    text-decoration:none;
  }
  &-underline {
    text-decoration:underline;
    &--hover {
      &:hover {
        text-decoration:underline;
      }
    }
  }
}
.e-text-align-right {
  text-align: right;
  &--mobile {
    @include mobile {
      text-align: right !important;
    }
  }
}
.e-text-align-left {
  text-align: left;
}

.e-text-transform {
  &-uppercase {
    text-transform:uppercase
  }
}

.e-text-color-alert , .e-color-alert {
  color: red;
}
.e-text-color-white  {
  color:#FFF !important;
  &--mobile {
    color:#FFF !important;
  }
}
.e-text-color-gray {
  color:$branding_gray !important;
  &--light {
    color:$branding_gray--light;
  }
}
.e-text-color-red {
  color:$branding_red !important;
}
.e-text-color-blue {
  color:$branding_blue !important;
}

.e-text-color-brown {
  color:$branding_brown !important;
  &--light {
    color:#B99678 !important;
  }
  &--extra-light {
    color:#DCCBBE !important;
  }
}
.e-text-light-300 {
  font-weight: 300 !important;
}
.e-text-normal-400 {
  font-weight: 400 !important;
}
.e-text-medium-500 {
  font-weight: 500 !important;
}
.e-text-medium-700 {
  font-weight: 700 !important;
}


.e-text-color-black--only-mobile {
  @include mobile {
    color:$branding_black !important;
  }
}
.e-text-color-gray--only-mobile {
  @include mobile {
    color:$branding_gray !important;
  }
}
.e-text-color-blue--only-mobile {
  @include mobile {
    color:$branding_blue !important;
  }
}


.e-text-bold {
  font-weight: bold;
}
.e-text-semi-bold {
  font-weight: 500;
}


.e-text-align-center {
  text-align: center;
  @include mobile {
    &--mobile {
      text-align: center !important;
    }
  }
}

.e-text-align-inherit {
  text-align:inherit;
  @include mobile {
    &--mobile {
      text-align:inherit;
    }
  }
}

.e-text-justify {
  text-align: justify;
}

.e-text-left--mobile ,
.e-text-align-left--mobile 
{
  @include mobile {
    text-align: left;
  }
}



// E-text increase & decrement
.e-text-increase-0 {
	&-9 {
		font-size: .9em;
	}
	&-8 {
		font-size: .8em;
	}
	&-7 {
		font-size:.7em;
	}
}
.e-text-increase-1 {
	font-size: 1em;
	@include mobile {
		&--mobile {
			font-size: 1em !important;
		}
	}
	&-1 {
		font-size: 1.1em !important;
		@include mobile {
			&--mobile {
				font-size: 1.1em !important;
			}
		}
	}
	&-2 {
		font-size: 1.2em !important;
		@include mobile {
			&--mobile {
				font-size: 1.2em !important;
			}
		}
	}
	&-3 {
		font-size: 1.3em !important;
		@include mobile {
			&--mobile {
				font-size: 1.3em !important;
			}
		}
	}
	&-4 {
		font-size:1.4em !important;
		@include mobile {
			&--mobile {
				font-size: 1.4em !important;
			}
		}
	}
	&-5 {
		font-size: 1.5em !important;
		@include mobile {
			&--mobile {
				font-size: 1.5em !important;
			}
		}
	}
	&-6 {
		font-size: 1.6em !important;
		@include mobile {
			&--mobile {
				font-size: 1.6em !important;
			}
		}
	}
	&-7 {
		font-size: 1.7em !important;
		@include mobile {
			&--mobile {
				font-size: 1.7em !important;
			}
		}
	}
	&-8 {
		font-size: 1.8em !important;
		@include mobile {
			&--mobile {
				font-size: 1.8em !important;
			}
		}
	}
	&-9 {
		font-size: 1.9em !important;
		@include mobile {
			&--mobile {
				font-size: 1.9em !important;
			}
		}
	}

}
.e-text-increase-2 {
	font-size: 2em !important;
	&-1 {
		font-size: 2.1em !important;
	}
	&-2 {
		font-size: 2.2em !important;
	}
	&-3 {
		font-size: 2.3em !important;
	}
	&-4 {
		font-size:2.4em !important;
	}
	&-5 {
		font-size:2.5em !important;
	}
	&-6 {
		font-size:2.6em !important;
	}
	&-7 {
		font-size:2.7em !important;
	}
}
.e-text-increase-3 {
	font-size: 3em !important;
	&-1 {
		font-size: 3.1em !important;
	}
	&-2 {
		font-size: 3.2em !important;
	}
	&-3 {
		font-size: 3.3em !important;
	}
	&-4 {
		font-size:3.4em !important;
	}
}
.e-text-increase-3 {
	font-size: 3em;
	&-1 {
		font-size: 3.1em;
	}
	&-2 {
		font-size: 3.2em;
	}
	&-3 {
		font-size: 3.3em;
	}
	&-4 {
		font-size:3.4em;
	}
}
.e-text-decrement-1 {
	font-size: 1em;
}
.e-text-decrement-0 {
	&-9 {
		font-size: .9em !important;
	}
	&-8 {
		font-size: .8em !important;
	}
	&-7 {
		font-size: .7em !important;
	}
	&-7 {
		font-size: .6em !important;
	}
	&-5 {
		font-size: .5em !important;
	}
}
.e-text-em-1-3-mobile {
	@include mobile {
		font-size: 1.3em !important;
	}
}
.e-text-em-1-9-mobile {
	@include mobile {
		font-size: 1.9em !important;
	}
}