// e-height
.e-height-100 {
  height:100%;
}
.e-height-px {
  &-50 {
    height: 50px;
    max-height: 50px;
  }
  &-60 {
    height: 60px;
    max-height: 60px;
  }
  &-70 {
    height: 70px;
    max-height: 70px;
  }
  &-120 {
    height: 120px;
    max-height: 120px;
  }
}