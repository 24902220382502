.footer {
  clear:both;	
  margin-top: 2em; 
  font-size: .8em;

  p {
    padding:0;
    margin-top:0;
    margin-bottom: .6em;
  }
  a {
    text-decoration: none;
    font-weight: 600;
    @extend .dim;
  }
}