/* 1 Globals / Abstracts */
@import 
"global/vars",
"global/mixins"
;

/* Vendors */
@import "../../node_modules/tachyons-sass/tachyons"; //https://tachyons.io/
@import "../../node_modules/flexboxgrid/css/flexboxgrid"; //http://flexboxgrid.com/


//@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css"; //http://fancyapps.com/fancybox/3/
// @import "../../node_modules/material-scrolltop/dist/material-scrolltop"; //https://github.com/bartholomej/material-scrolltop
@import "../../assets/vendor/slick-carousel/slick/slick.css"; 
@import "../../assets/vendor/slick-carousel/slick/slick-theme.css";


/* 2 Base */
@import 
"base/fonts",
"base/theme"
// @import "base/wp";
;

// 3 u- Utils 
@import 
"utils/u-ratio",
"utils/--dev"
;

// 4 Partials
@import 
"partials/body",
"partials/header",
"partials/main",
"partials/footer"
;


// 5 State / State JS
@import 
"state/s-general"
;


// 6 Vendor (Modified)
@import 
"vendor/v-flexboxgrid",
"vendor/v-thachyons",
// "vendor/v-material-scrolltop"
"vendor/v-slick"
// @import "vendor/v-fancybox";
;

// 7 Components


// 8 Elements 
@import 
"elements/e-container",
"elements/e-margin",
"elements/e-padding",
"elements/e-text",
"elements/e-animated",
"elements/e-object",
"elements/e-image",
"elements/e-background",
"elements/e-color",
"elements/e-cursor",
"elements/e-only",
"elements/e-displays",
"elements/e-min-max",
"elements/e-summary",
"elements/e-border",
"elements/e-message",
"elements/e-style-number-theme",
"elements/e-scroll-horizontal",
"elements/e-list-dots",
"elements/e-btn",
"elements/e-clear",
"elements/e-height",
"elements/e-shadow",
"elements/e-content-center",
"elements/e-dl-number",
"elements/e-general"
;





