.header {
  overflow-x:hidden;
  .header__top {
    display:flex;
    flex-wrap:wrap;
    // justify-content:space-between;
    position:relative;
    @include mobile {
      &::before {
        content:("");
        position:absolute;
        width:110%;
        bottom:0;
        left:-10px;
        height:10px;
        display:block;
        box-shadow:0px 5px 3px #CCC;

      }
    }
    &.is-active-mobile  {
      &::before {
        display:none;
      }
    }
  }
  .header__lang {
    display:flex;
    align-items: center;
    transform:translateY(-8px);
    .body--lang-es_ES & .header__lang-item-es a, 
    .body--lang-ca & .header__lang-item-ca a 
    {
      font-weight:bold;
    }
    @include mobile {
      display:none;
    }
    &--mobile {
      display:none;
      @include mobile {
        transform:translateY(0px);
        display:flex;
        flex-direction: row;
        width:100%;
        justify-content:center;
        padding:1em 0;
      }
    }
  }

  .header__logo {
    max-width: 80%;
    img {
      max-width: 90%;
    }
  }

  .header__menu {
    @include reset_list;
    a {
      text-decoration: none;
    }
  }
  @media screen and (min-width: $mobile) {


    #access ul {
      display:flex;
      flex-wrap: wrap;

      background-image: url($path + "assets/img/menu_down.png");
      background-position: right center;
      background-repeat: no-repeat;}

      #access ul li a {

        background-image: url($path + "assets/img/picto-menu.png");

      }


      /* qui som */
      #access ul li.page-item-8 a {background-color: #C3CF29}

      /* perque */
      #access ul li.page-item-18 a {background-color: #00B4E4}

      /* productes */
      #access ul li.page-item-10 a {background-color: #E65196}

      /* recursos */
      #access ul li.page-item-12 a {background-color: #FBC31C}

      /* activitats */
      #access ul li.page-item-14 a {background-color: #E43C23}

      /* contacta */
      #access ul li.page-item-16 a {background-color: #55AC3A}


      /* contacta */
      #access ul li.page-item-276 a {background-color: #5D296B;margin-right:0;}




      #access {height: 110px}
      #access a {

        color: #EFEFEE;
        display: block;
        background-color: red;

        font-weight: 400;

        line-height: 35px;
        margin: 0 18px 0 0;
        text-decoration: none;
        width: 91px;
        height: 91px;
        line-height: 140px;
        text-indent: 5px;
        display: block;

        max-height:91px;

        width: 99px;

        -moz-transition: 0.1s;
        -ms-transition: 0.1s;
        -o-transition: 0.1s;
        -webkit-transition:0.1s;
        transition: 0.1s;

        @media screen and(max-width: 870px) {
          width:82px;
          height:82px;
        }

      }
      #access a:hover { 

        max-height:110px;

        color:white;
        font-weight: bold;

        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        height: 110px;
        width: 99px;
        font-size: 105%;

      }
      #access ul ul {
        box-shadow: 0px 3px 3px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 3px 3px rgba(0,0,0,0.2);
        -webkit-box-shadow: 0px 3px 3px rgba(0,0,0,0.2);
        display: none;
        position: absolute;
        top: 35px;
        left: 0;
        float: left;
        width: 180px;
        z-index: 99999;
      }
      #access ul ul li {
        min-width: 180px;
      }
      #access ul ul ul {
        left: 100%;
        top: 0;
      }
      #access ul ul a {

        /*background-color: #003D1F;*/
        background-repeat: repeat;
        background-image: none;
        background-color: #FFF;

        background: url( $path + "assets/img/white-opacity-80.png") repeat scroll 0 0;

        line-height: 1em;
        padding: 10px 5px 10px 22px;
        border-bottom:1px solid #000;
        width: 190px;
        height: auto;
        border-right:none !important;
        color: #000;


      }
      #access ul.children > li a {
       position: relative;
       text-transform: none;
       font-weight: 400;
       transition: all 100ms ease-out 30ms;}
       #access ul > li a:hover {} /* funciona */
       #access ul ul.chidren li a :hover  {

        color: #000;
        font-weight: 600;
      }

      #access ul ul li:hover > a,
      #access ul ul:hover > a {

        color: #000 !important
      }
      #access ul li:hover > ul {
        display: block;
      }
      #access ul li.current_page_item a ,
      #access ul li.current_page_parent a ,
      #access ul li.current_page_ancestor a,

      body.tax-categorias_recursos & #access ul li.page-item-12 a ,

      body.page-id-223 & #access ul li.page-item-14 a,

      body.single-activitat & #access ul li.page-item-769 a ,

      body.single-post & #access ul li.page-item-276 a

      {
        max-height:110px;

        color:white;
        font-weight: bold;

        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        height: 110px;
        background-image: url( $path + "assets/img/picto-menu.png");

      }
    }
    @include mobile {

      border-bottom:1px solid $branding_gray--light2;

      .header__menu  {
        display:none;
        &.is-active {
          display:block;
        }
      }
      

      /* qui som */
      #access ul li.page-item-8 a {background-color: #C3CF29}

      /* perque */
      #access ul li.page-item-18 a {background-color: #00B4E4}

      /* productes */
      #access ul li.page-item-10 a {background-color: #E65196}

      /* Activitat */
      #access ul li.page-item-769 a {background-color: red}

      /* recursos */
      #access ul li.page-item-12 a {background-color: #FBC31C}

      /* activitats */
      #access ul li.page-item-14 a {background-color: #E43C23}

      /* contacta */
      #access ul li.page-item-16 a {background-color: #55AC3A}


      /* contacta */
      #access ul li.page-item-276 a {background-color: #5D296B;margin-right:0;}

      #access {
        width:100%;
        ul {
          width:100%;
          li {
            width:100%;
            a {
              display:block;
              padding:1em .5em;
              width:100%;
              text-align: center;
              height:auto;
              color:#FFF;
              transition:.2s;
              &:hover {
                opacity: .8;
              }
            }
          }
        }
      }
    }
  }