div.post {
  max-width: 100%;
}
.widjet-right {
  a {
    @extend .link , .dim;
  }
  .current-cat {
    font-weight:bold;
  }
}

#main {
  .postWrapper {
    max-width: 100%;
    margin:0 auto !important;
  }
  &.post {
    margin:0 auto !important;
  }
}

#body.page-template-mapa-web {
  #main {
    .post {
      @extend .row;
      .grid_3 {
        @extend .col-xs-12;
        @extend .col-sm-4;
        a {
          text-decoration:none !important;
          &:hover {
          @extend .dim;
          }
        }
      }
      .children {
        margin-top: 1em;
        padding-left:1em;
      }
    }
  }
}



#body table {
  @include mobile {
    display:block;
    width:100% !important;
    tr  {
      display:flex;
      flex-wrap: wrap;
      width:100% !important;
      td {
        height:auto !important;
        width:100% !important;
      }
    }
  }
}


/* Contact 7 */
.wpcf7-form input ,
.wpcf7-form textarea 
{
  height: 20px;
  line-height: 20px;
  padding: 12px 4px;
  border-radius:0;
  border:1px solid #A4B3CD;
}

.wpcf7-form textarea 
{
  width: 400px;
  max-width: 100%;
  height: 100px;
}
.wpcf7-form input.wpcf7-submit {
  background-color: #a4b3cd;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 25px;
  line-height:50%;
  margin-top: 20px;
  width:250px;
  max-width: 100%;
  padding:16px;
  @extend .dim;
}





h1.postTitle ,
{
  color: #7585A0;
  display: block;
  font-size: 3em;
  line-height: 28px;
}
#main h2 {
    color: #7585A0;
    display: block;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 18px !important;
    padding: 10px 0 0 0 !important;
    text-transform: uppercase;
}

a.button {
  background-color: #A4B3CD;
  color: #FFF;
  min-height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}


.post {
  font-size: .9em;
  line-height:1.5em;
  ul {
    padding-left:0;
    list-style: inside;
    li {
      margin-bottom: .7em;
    }
  }
}

/* General */
#body #main {
  background: url($path + "/assets/img/background-body.jpg") no-repeat bottom center;
}
/* Qui Som */
#body.page-id-8 #main {
  background: url($path + "/assets/img/background-quisom-kmates.jpg") no-repeat bottom center;
  @include mobile {
    background-size:150% auto;
  }
}
/* Per Què? */
#body.page-id-18 #main {
  background: url($path + "/assets/img/background-perque-kmates.jpg") no-repeat bottom center;
  @include mobile {
    background-size:150% auto;
  }
}
/* Contacta */
#body.page-id-16 #main {
  background: url($path + "/assets/img/background-contacte-kmates.jpg") no-repeat bottom center;
   @include mobile {
    background-size:130% auto;
  }
}

h2.postTitle_notice, 
h1.postTitle_notice 
{
    color: #7585a0;
    font-weight: 300 !important;
    line-height: 44px !important;
    padding-top: 10px !important;
    text-transform: capitalize !important;
      font-size: 2em;
      line-height:1.2em;

    a {
      text-decoration:none;
    }
}



// https://codepen.io/designcouch/details/Atyop
#nav-icon2  {
  width: 60px;
  height: 45px;
  position: absolute;
  margin: 20px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  right:10px;

}


/* Icon 2 */
#nav-icon2 {
  display:none;
  @include mobile {
    display:block;
  }
}

#nav-icon2 span {
  display: block;
  position: absolute;

  height: 4px;
  width: 50%;
  background: #d3531a;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 6px 6px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 6px 0 0 6px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 16px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 30px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}










// GENERAL THEME EXTRAS
.e-filter-image-color-brown {
  filter: grayscale(100%) sepia(100) contrast(1) invert(0) saturate(0);
  // filter: none | blur() | brightness() | contrast() | drop-shadow() | grayscale() | hue-rotate() | invert() | opacity() | saturate() | sepia() | url();
  transition:.5S;
  &:hover {
    filter:initial
  }
}

.e-title {
  font-size:4rem;
  margin:0 0 0 0;
  padding:0;
  @include mobile {
    font-size:2.5rem;
  }
}

.e-no-radius {
  border-radius:0 !important;
}

.e-hr-theme {
  border:0;
  display:block;
  width:100%;
  height:1px;
  background-color:rgba(#EAEAEA,.6);
}

// Effect Oscure Banner element
.e-oscure-banner {
	position:relative;
	img {
		display: block;
	}
	&:before {
		content:("");
		background-color:rgba(#000,.25);
		width: 100%;
		height:100%;
		left:0;
		top:0;
		position: absolute;
		transition:1s;
	}
	&--opacity-50  {
		&:before {
			background-color:rgba(#000,.50);
		}
	}
}


// Order
.e-order {
	&-5 {
		order:5;
	}
}


// E-play-video
.e-play-video {
	a {
		display:block;
		&::after {
			content:("");
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background:url('assets/svg/boton-de-play.svg') no-repeat center center;
			background-size:50% 50%;
			z-index:2
		}
		img {
			display:block;
			filter: brightness(.5) invert(0) grayscale(0);
			transition:.5s;
		}
		&:hover {
			img {
				filter: brightness(1) invert(0) grayscale(0);
			}
		}
	}
}

// special font 
.e-font-adami {
	font-family: 'Adamina', serif;
}
.e-font-cabin {
  font-family: 'Cabin',sans-serif;
}

.e-bb-0 {
	border:0 !important;
}
.e-color-fill-white {
  svg path {
    fill:#FFF;
  }
}


// E-FILL
.e-fill-white {
	svg {
		fill:#FFF;
	}
}


.e-link-cool {
	border-bottom: 1px solid #CCC;
	display: inline-block;
	line-height: 0.85;
	text-shadow:
	2px 2px white,
	2px -2px white,
	-2px 2px white,
	-2px -2px white;
}


// E-ICO : ICONS by SVG
.e-ico {
	font-size: 0;
	svg {
		width:24px;
		height:24px;
	}
	&--facebook {
	}
	&--instagram {
	}
	&--phone {
		svg {
			width:14px;
			height:14px;
			margin-right: 4px;
			transform: translateY(2px);
		}
	}
}
.e-icon {
	display: inline-block;
	vertical-align: middle;
	margin-left:1em
}



