
#body {
  .slick-prev {
    left: 25px;
    z-index:100;
  }

  .slick-next {
    right: 25px;
    z-index:100;
  }

  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

.slick-dots {
  position: absolute;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0px;
  list-style: none;
  text-align: center;
  @include mobile {
    bottom: -6px;

  }
}
.slick-dots li {
  margin:0 12px;
  @include mobile {
    margin:0 6px;
  }
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;

  width: 20px;
  height: 20px;
  display:block;
  background:#FFF;
  @include mobile {
    background-color:red;
  }
  &:hover {
    &:before {
      background:#c4c4c4;
      @include mobile {
        background:#000;
      }

    }
  }
  @include mobile {
    width: 12px;
    height: 12px;
  }
}
.slick-dots li button[aria-selected=true]:before {
  background:#c4c4c4;
  @include mobile {
        background:#444;
      }
}


.slick-dots li button:before {
  font-family: 'slick';
  font-size: 24px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;
  display:block;
  background:#FFF;
  content: '';
  text-align: center;
  opacity: .50;
  color: $branding_black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include mobile {
    width: 12px;
    height: 12px;
  }
}



.slick-dots li.slick-active button:before {
  // opacity: 1;
  // color: #FFF;
}